.am-wingblank {
  margin-left: 8px;
  margin-right: 8px;
}
.am-wingblank.am-wingblank-sm {
  margin-left: 5px;
  margin-right: 5px;
}
.am-wingblank.am-wingblank-md {
  margin-left: 8px;
  margin-right: 8px;
}
.am-wingblank.am-wingblank-lg {
  margin-left: 15px;
  margin-right: 15px;
}
